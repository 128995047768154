/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef  } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/bdwend.glb')

  return (

    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.core.geometry}
        material={materials.Material1}
        position={[0, 0.3, 0.03]}
        rotation={[-0.21, 0.08, 0.0]}
        scale={[0.01, 0.011, 0.01]}
        roughness={0.5}
        metalness={0.8}
      />
      <mesh
        geometry={nodes.shell.geometry}
        material={materials.Material3 }
        material-transparent
        material-opacity={0.3}
        material-roughness={0.2}
        material-metalness={0.8}
        position={[0, 0.3, 0.03]}
        rotation={[-0.21, 0.08, -0.02]}
        scale={[0.01, 0.011, 0.01]}
       >
      </mesh>
      <mesh
        geometry={nodes.headring.geometry}
        material={materials.Material2 }
        position={[0.01, 0.35, 0.1218]}
        rotation={[1.36, -0.02, -0.092]}
        scale={[0.01, 0.0088, 0.01]}
        material-roughness={0.6}
        material-metalness={0.8}
      />
    </group>
  )
}

useGLTF.preload('/models/bdwend.glb')
